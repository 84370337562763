@import '@crystal-eyes/constants.scss';

.cls-1 {
  fill: none;
}
.cls-2 {
  fill: #01293d;
  @mixin color-mixin($color) {
    fill: $color;
  }
  &.D {
    @include color-mixin($disc-D);
  }
  &.Di {
    @include color-mixin($disc-Di);
  }
  &.DI {
    @include color-mixin($disc-DI);
  }
  &.Id {
    @include color-mixin($disc-Id);
  }
  &.I {
    @include color-mixin($disc-I);
  }
  &.Is {
    @include color-mixin($disc-Is);
  }
  &.IS {
    @include color-mixin($disc-IS);
  }
  &.Si {
    @include color-mixin($disc-Si);
  }
  &.S {
    @include color-mixin($disc-S);
  }
  &.Sc {
    @include color-mixin($disc-Sc);
  }
  &.SC {
    @include color-mixin($disc-SC);
  }
  &.Cs {
    @include color-mixin($disc-Cs);
  }
  &.C {
    @include color-mixin($disc-C);
  }
  &.Cd {
    @include color-mixin($disc-Cd);
  }
  &.CD {
    @include color-mixin($disc-CD);
  }
  &.Dc {
    @include color-mixin($disc-Dc);
  }
  &.white {
    @include color-mixin($white);
  }
}
.cls-3 {
  fill: #a2e2fc;

  &.discType {
    fill: #fff;
  }
}
.cls-4 {
  fill: #0283aa;

  &.discType {
    fill: #fff;
  }

  &.white {
    fill: #01293d;
  }
}
.cls-5 {
  fill: #22b2e3;

  &.discType {
    fill: #fff;
  }

  &.white {
    fill: #01293d;
  }
}
.cls-6 {
  clip-path: url(#clip-path);
}
.cls-7 {
  fill: #b5360f;
}
.cls-8 {
  clip-path: url(#clip-path-2);
}
.cls-9 {
  fill: #1fc3ea;

  &.discType {
    fill: #fff;
  }
}
