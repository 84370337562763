@import '@crystal-eyes/constants.scss';

.wrapper {
  position: relative;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $crystal-background-gray;
  &.white {
    background: #fff;
  }
}
