@import '@crystal-eyes/constants.scss';

.wrapper {
  background: $crystal-midnight-blue;
  height: 100%;
}

.section-content {
  max-width: 1080px;
  margin: 0 auto;
  padding: 12px 16px;
  @media (max-width: 600px) {
    padding: 0;
  }
}

.footer-wrapper {
  margin: 80px 0 60px;

  &.basicsOnly {
    margin: 60px 0;
  }

  @media (max-width: 600px) {
    margin: 0 0 60px;
  }
}

.footer-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 600px) {
    flex-direction: column;
    font-family: var(--font-lato);
    font-weight: 700;
    text-align: center;
  }
}

.footer-column {
  color: #fff;
  flex: 1;
  max-width: 160px;
  @media (max-width: 600px) {
    max-width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.column-header {
  @media (max-width: 600px) {
    padding: 16px;
    transition: all 0.2s;
    &:active {
      background: rgba($mid-blue, 0.2);
    }
  }
}

.footer-links {
  border-left: 1px solid $mid-blue;
  padding-left: 12px;
  margin-top: 16px;
  @media (max-width: 600px) {
    border-left: 0px solid $mid-blue;
    padding-left: 0;
    transform-origin: top;
    transition: all 0.3s;
    max-height: 0;
    transform: scaleY(0);
    margin-top: 0;
    position: relative;
    &.expanded {
      max-height: 240px;
      transform: scaleY(1);
      padding: 24px 0;
      &:before {
        content: '';
        position: absolute;
        left: calc(50% - 40px);
        top: 0;
        width: 80px;
        height: 1px;
        background-color: $mid-blue;
      }
    }
  }
}

.footer-link {
  display: block;
  padding: 10px 0;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  transition: all 0.2s;
  font-family: var(--font-lato);
  font-weight: 400;
  &:hover {
    color: $mid-blue;
    text-decoration: none;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}

.footer-small-link {
  margin: 0;
  color: #fff;
  transition: all 0.2s;

  &:hover {
    color: $mid-blue;
  }
}

.footer-social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  @media (max-width: 600px) {
    margin-top: 24px;
  }
  img {
    height: 16px;
    padding: 0 12px;
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      filter: brightness(110%);
    }
  }
}

.footer-details {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  margin-top: 16px;
}

.footer-bar {
  color: $mid-blue;
  margin: 0 8px;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 6px;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.2);
  }
}

.crystal-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;

  &:hover {
    text-decoration: none;
  }
}

.logo-text {
  color: $white;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 16px;
  font-style: italic;
}

.logo-line {
  height: 1px;
  margin: 0 8px;
  background-color: #ebebeb;
}

.crystal-logo {
  width: 100px;
}

.logo-container {
  display: grid;
  grid-template-columns: 1fr 140px 1fr;
  align-items: center;
  width: 275px;
}
